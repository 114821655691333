@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&&display=swap');

body {
  margin: 0;
  background: #f2f4f8;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=text],
select,
input[type=submit],
input[type=button] {
  font-family: 'Roboto',
  sans-serif;
  font-size: 15px;
  font-weight: 400;
}