@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&&display=swap);
body {
  margin: 0;
  background: #f2f4f8;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=text],
select,
input[type=submit],
input[type=button] {
  font-family: 'Roboto',
  sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url(/static/media/bg-login.e73d4290.jpg) no-repeat center;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.login-wrapper h1 {
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 300;
}

.login-wrapper form {
  padding: 0;
  align-items: flex-end;
}

.login-wrapper span {
  width: 100%;
  display: block;
  color: #f25151;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: right;
  font-size: 16px;
}

.login-wrapper__inner {
  background: rgba(23, 25, 72, 0.95);
  box-shadow: 2px 2px 43px 10px #8ad3fd;
  border-radius: 8px;
  padding: 60px 40px 30px 40px;
  color: #fff;
  min-width: 320px;
  animation: boxShadow infinite 3s both;
}

@keyframes boxShadow {
  0% {
    box-shadow: 2px 2px 43px 10px #8ad3fd;
  }
  50% {
    box-shadow: 2px 2px 43px 20px #8ad3fd;
  }
  100% {
    box-shadow: 2px 2px 43px 10px #8ad3fd;
  }
}

.login-wrapper__inner h1 {
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 20px;
}

.login-wrapper__inner label {
  text-transform: uppercase;
  margin: 20px 0 10px 0;
  font-weight: 700;
  color: #b1d3ff;
  width: 100%;
  display: block;
}

.login-wrapper__inner input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #60a5ff;
  color: #fff;
  padding: 2px 0px;
  border-radius: 0;
  transition: all .4s;
  width: 100%;
  outline: none;
}

.login-wrapper__inner input:focus {
  border-bottom: 1px solid #fafeff;
}

.login-wrapper__inner button {
  background: #b1d3ff;
  border-radius: 6px;
  border: none;
  text-transform: uppercase;
  transition: all .4s;
  padding: 12px 26px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

.login-wrapper__inner button:hover {
  background: #fafeff;
  cursor: pointer;
}
/*# sourceMappingURL=Login.css.map */
#navigation {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 70px;
}

#navigation a {
  color: #fff;
}

#navigation a:hover {
  text-decoration: none;
  color: paleturquoise;
}

nav {
  display: inline-block;
  width: auto;
}

#nav-edges {
  display: none;
}

#nav-edges.active {
  display: block;
}

#dropNodes {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 1;
}

#dropNodes.disable {
  filter: grayscale(1);
  opacity: 0.8;
  pointer-events: none;
}

#dropNodes.disable:hover {
  cursor: not-allowed;
}

#dropNodes.invisible {
  display: none;
}

.invisible {
  display: none;
}

#nav-main {
  width: 100%;
  position: absolute;
  display: flex;
  left: 0;
  top: 0;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  background-color: #0246a7;
  box-sizing: border-box;
  color: #fff;
  transform: translateY(-300px);
  transition: transform  .6s ease-in-out;
  z-index: 1;
}

#nav-main.active {
  transform: translateY(0);
}

#nav-main input {
  width: 40px;
  height: 20px;
  margin-right: 10px;
  margin-left: 20px;
}

#nav-main select {
  margin-bottom: 0;
  height: 20px;
  margin-left: 10px;
}

#nav-main .input__file {
  height: 20px;
  width: 80%;
  box-sizing: border-box;
  background: transparent;
}

.nav input {
  width: 40px;
  border: 0;
}

.item-menu-main:hover > .menu--submenu {
  display: flex;
}

.menu--main {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
}

.menu--main li {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px 26px 14px 20px;
  position: relative;
  min-width: 220px;
}

.menu--submenu {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 46px;
  display: none;
  flex-direction: column;
  z-index: 10;
  background-color: #396688;
  min-width: 200px;
}

.menu--submenu li {
  padding: 0;
  position: relative;
}

.menu--submenu .nav-link {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid #9c9b9b;
}

.menu--submenu .nav-link.active {
  background-color: #36b603;
  color: #000000 !important;
}

.menu--submenu .nav-link.disable {
  background-color: #888888;
  color: #000000 !important;
}

.menu--submenu .nav-link.disable:hover {
  background-color: #888888;
  color: #000000 !important;
  cursor: not-allowed;
}

.menu--submenu .nav-link:hover {
  background-color: #00c0ff;
  text-decoration: none;
  color: #000 !important;
}

.menu--user {
  display: flex;
  align-items: center;
}

.menu--user span {
  font-weight: 700;
}

.menu--user li {
  margin-right: 16px;
}

.nav-link--logout {
  border-radius: 4px;
  font-size: 13px;
  padding: 5px 10px;
  background-color: #00c0ff;
  transition: all .4s;
}

.nav-link--logout:hover {
  color: #ffffff !important;
  background-color: #40CEE3;
}

#nav-actions {
  min-width: 150px;
  opacity: 0;
  transition: .4s;
}

#nav-actions.active {
  opacity: 1;
}

.edges-type {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border: 0;
  border-radius: 2px;
}

.nav-item {
  position: relative;
}

#menu-edges {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 20px;
}

#menu-edges ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#menu-edges .nav-link {
  padding: 10px 8px;
  border-radius: 3px;
  color: #ffffff;
  background: #777777;
  text-decoration: none;
  display: flex;
  margin: 8px 0;
  font-size: 12px;
  display: block;
  transition: background .4s;
}

#menu-edges .nav-link.active {
  background-color: #01a6de;
  color: #ffffff;
}

.nav-link--btn {
  padding-right: 46px !important;
  min-width: 54px;
}

.disable {
  cursor: not-allowed;
}

.btn--grid {
  opacity: .3;
  height: 20px;
  margin-left: 5px;
}

.btn--grid.active {
  opacity: 1;
}

.btn--grey {
  background-color: #068686 !important;
}

button.accept {
  background-color: green !important;
}

button.abort {
  background-color: #bd0101 !important;
}
/*# sourceMappingURL=menu.css.map */
nav {
  width: 100%;
  display: flex;
}

nav ul li {
  display: inline-block;
}

nav .nav-link {
  padding: 5px 20px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}

.menu-user {
  display: flex;
  align-items: center;
}

#btn-menu {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 50px;
  height: 26px;
  border-radius: 20px;
  z-index: 100;
  border: 2px solid black;
  background-color: #d4d4d4;
}

#btn-menu:after {
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  background-color: #020202;
  transition: transform .2s;
}

#btn-menu:hover {
  cursor: pointer;
}

#btn-menu.active {
  border: 2px solid #000000;
  background-color: #ffffff;
}

#btn-menu.active:after {
  background-color: black;
  transform: translateX(22px);
}

.modal textarea, .modal input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-family: 'Verdana';
  font-size: 12px;
}

.modal label {
  width: 100%;
}

#modalTitle h2 {
  font-size: 26px;
  margin-top: 10px;
}

.modal .form-row {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 10px;
}

.modal label {
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.modal .disable {
  display: none;
}

.modal-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.modal-btn button {
  min-width: unset !important;
  height: unset !important;
  padding: 10px 16px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.btn-prev-next {
  padding-top: 16px;
  margin-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b9B9B9;
  border-top: 1px solid #b9B9B9;
}

.btn-prev-next button {
  font-size: 12px;
  height: 40px !important;
  background: #04aca3 !important;
}

.btn-prev-next button:hover {
  background: #037a74 !important;
}

.btn-prev-next .btn-next {
  margin-left: auto;
}

.btn-prev-next .disable {
  display: none;
}

#modalDialog {
  background: rgba(0, 0, 0, 0.1);
}

#modalDialog form {
  margin-top: 4px !important;
}

#modalDialog .modal-body {
  width: 320px;
  padding: 20px;
  left: 0;
  transform: translateX(0);
}

.nameEdges {
  font-size: 12px;
}

#modalInfo .modal-btns button {
  min-width: 40%;
}

#modalSaveDialog .modal-btns {
  justify-content: flex-end;
}

#modalSaveDialog .modal-btns button {
  margin-left: 20px;
}

#modalSaveDialog .modal-body {
  width: 440px;
}

.app {
  overflow: hidden;
}

header {
  background-color: #282c34;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

canvas {
  border: 2px solid #1D2D5F !important;
  margin: 0px -40px;
  background: #c9dbf7;
}

h1 {
  font-size: 20px;
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.main {
  height: 100vh;
  /*calc(100vh - 60px);*/
  overflow: hidden;
}

#rootvis {
  transform: translateY(0px);
  transition: transform .6s ease-in-out;
  width: 100%;
}

#rootvis.active {
  transform: translateY(43px);
}

.rootVis {
  height: 100%;
  overflow: hidden;
}

.vis-network {
  border: none;
  outline: none;
}

.col {
  flex: 50% 1;
  position: relative;
}

.col-100 {
  flex: 100% 1;
}

.col-80 {
  flex: 80% 1;
}

.col-50 {
  flex: 50% 1;
}

.col-20 {
  flex: 20% 1;
}

/* Admin */
.admin {
  height: 100vh;
  overflow-y: auto;
}

form {
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.formRow {
  margin-bottom: 25px;
  padding-top: 25px;
  display: flex;
}

.formRow + .formRow {
  border-top: 2px solid #dfdfdf;
}

input, select {
  flex: 1 1 auto;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  padding: 0 10px;
  margin-bottom: 5px;
  background: #fff;
}

input[type=submit] {
  border: none;
  color: #fff;
  background: #e04c6c;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 10px;
  text-transform: uppercase;
  margin-right: 10px;
}

.button {
  border: 1px solid #282c34;
  background: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  text-transform: uppercase;
}

button.delete {
  border-color: #e04c6c;
}

.mt-10 {
  margin-top: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

canvas {
  width: auto;
  height: 740px;
}

pre {
  font-size: 13px;
  line-height: 1;
}

.admin pre {
  float: left;
  margin-right: 30px;
}

.actions {
  position: absolute;
  height: auto;
  top: 50px;
  right: 0;
  padding: 20px 0px;
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border-bottom-left-radius: 10px;
  border-top: 0;
}

.actions:hover {
  background: #cfe4f1;
  cursor: pointer;
  transition: all .6s;
}

.zoomIn, .zoomOut {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 0 auto 10px;
  fill: #36373e;
  opacity: 0.7;
}

.zoomIn:hover, .zoomOut:hover {
  opacity: 1;
}

.levelWrap {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.levelWrap .levelWrap__inner {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.levelWrap:hover .levelBtn {
  opacity: 1;
  display: block;
}

.levelWrap .levelBtn {
  padding: 7px;
  border: 2px solid #36373e;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  margin: 0 2px;
  cursor: pointer;
  opacity: 0;
  display: none;
}

.levelWrap .levelBtn.active {
  opacity: 1;
  background: #0050bf;
  color: #fff;
  display: block;
}

.levelWrap .levelBtn:hover {
  opacity: 1;
}

table, tr, td {
  border: 1px solid black;
  font-size: 10px;
}

#edge-popUp {
  display: none;
  position: absolute;
  top: 350px;
  left: 170px;
  z-index: 299;
  width: 250px;
  height: 90px;
  background-color: #f9f9f9;
  border-style: solid;
  border-width: 3px;
  border-color: #5394ed;
  padding: 10px;
  text-align: center;
}

#Capa_1 {
  transform: rotate(45deg);
}

.opacity-1 {
  opacity: 1 !important;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.shapes--menu {
  position: relative;
  transition: .4s;
  margin: 5px auto;
}

.shapes--menu img {
  transition: all .4s;
}

.shapes--menu img:hover {
  opacity: 100%;
}

.shapes--menu img:nth-child(1) {
  transform: translateX(30px);
}

.shapes--menu img:nth-child(2) {
  transform: translateX(-19px);
}

.shapes--menu img:nth-child(3) {
  transform: translateX(-45px);
}

.shapes--menu:hover img {
  transform: scale(1.1) translateX(-50px);
}

.flex-column {
  flex-direction: column;
}

.shapes-menu--turtle {
  right: 5px;
}

.shapes-menu--hexagon img:nth-child(2) {
  transform: translateX(-17px);
}

.shapes-menu--hexagon:hover img:nth-child(2) {
  transform: translateX(-53px);
}

.shapes-menu--circle img:nth-child(2) {
  transform: translateX(-11px);
}

.shapes-menu--circle img:nth-child(3) {
  transform: translateX(-37px);
}

.shapes-menu--circle:hover img:nth-child(2) {
  transform: translateX(-48px);
}

.shapes-menu--circle:hover img:nth-child(3) {
  transform: translateX(-44px);
}

.shapes-menu--square img:nth-child(2) {
  transform: translateX(-17px);
}

.shapes-menu--square img:nth-child(3) {
  transform: translateX(-43px);
}

.shapes-menu--square:hover img:nth-child(2) {
  transform: translateX(-51px);
}

.shapes-menu--square:hover img:nth-child(3) {
  transform: translateX(-48px);
}

.shapes-menu--triangle-up img:nth-child(3) {
  transform: translateX(-47px);
}

.shapes-menu--triangle-up:hover img:nth-child(3) {
  transform: translateX(-53px);
}

.shapes-menu--triangle-down img:nth-child(3) {
  transform: translateX(-47px);
}

.shapes-menu--triangle-down:hover img:nth-child(3) {
  transform: translateX(-53px);
}

div.vis-network div.vis-manipulation {
  border: none;
  background: transparent;
  top: 0;
  left: 10px;
}

@keyframes nodetitle {
  0% {
    width: 0;
    color: transparent;
  }
  100% {
    width: 100%;
    color: #FFF;
  }
}

.nodetitle {
  color: white;
  display: inline-block;
  font-size: 20px;
  border: 0;
  padding: 10px 10px;
  border-radius: 0px;
  background: #5394ed;
  animation: nodetitle .2s ease-in;
  margin: -10px;
}

.nodetitle--size1 {
  background: #c94080 !important;
}

.nodetitle--size2 {
  background: #5394ed !important;
}

.nodetitle--size3 {
  background: #000000 !important;
}

.vis-tooltip {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

table.legend_table {
  font-size: 11px;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
}

table.legend_table,
td {
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
  padding: 2px;
}

div.table_content {
  width: 80px;
  text-align: center;
}

div.table_description {
  width: 100px;
}

#operation {
  font-size: 28px;
}

#modal, .modal {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 299;
  animation: modalopacity .7s;
}

@keyframes modalopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-body {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  max-width: 100%;
  background-color: #f9f9f9;
  padding: 20px 30px;
  border-radius: 6px;
  text-align: center;
}

.modal-body h2 {
  text-transform: uppercase;
  font-weight: 400;
  text-align: left;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 0;
  width: 100%;
}

.modal-body h2 span {
  font-weight: 600;
}

.modal-body h3 {
  width: 100%;
  text-align: left;
}

.modal-body p {
  text-align: left;
}

.modal-body form {
  width: 100%;
  margin: 30px 0 0 0;
  padding: 0;
}

.modal-body .form-row {
  width: 100%;
  display: flex;
  align-items: center;
}

.modal-body label {
  margin-right: 20px;
  font-weight: 600;
}

.modal-body input {
  border: 1px solid #E8E8E8;
  transition: all .4s;
  border-radius: 2px;
  height: 40px;
}

.modal-body input:focus {
  border-color: #20639b;
  outline: none;
  box-shadow: none;
}

.modal-body .modal-btns {
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}

.modal-body .modal-btns button {
  margin-left: 20px;
}

.modal-body button {
  padding: 14px 30px;
  background-color: #20639b;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: all .4s;
}

.modal-body button.disabled {
  background-color: #b9b9b9;
}

.modal-body button.disabled:hover {
  background-color: #b9b9b9;
  cursor: not-allowed;
}

.modal-body button:hover {
  cursor: pointer;
  background-color: #194e79;
}

.modal-body .modal-header {
  display: flex;
  border-bottom: 1px solid #dddddd;
  position: relative;
  margin-bottom: 20px;
}

.modal-body .modal-header button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: transparent;
  color: #000000;
  font-size: 20px;
  border: 1px solid #dddddd;
  min-width: unset;
}

.btn-cancel {
  background: #CD5C5C !important;
}

.btn-cancel:hover {
  background-color: #a84a4a !important;
}

#consoleLog {
  position: absolute;
  left: 10px;
  top: 54px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #6d6868;
  width: 70%;
  max-width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 12px;
}

.center-world {
  font-size: 10px;
  padding: 0 3px;
  width: 35px;
  height: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  margin-left: 4px;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4px;
  border-radius: 5px;
  border: 1px solid #707070;
}

.modal-input {
  display: flex;
  flex-direction: column;
}

.modal-input input {
  width: 100%;
  box-sizing: border-box;
}
/*# sourceMappingURL=App.css.map */
